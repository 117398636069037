import React, { useState } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { IoPhonePortraitOutline, IoLocationOutline, IoNotificationsOutline, IoCameraReverse } from "react-icons/io5"
import { loadUser } from "../actions/auth"
import UserProfileEditForm from "../components/UserProfileEditForm"
import EditProfilePicture from "../components/EditProfilePicture"
import axios from "axios"
import { toast } from "react-toastify"
import getAxiosConfig from "../utils/axiosConfig"
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
  Button,
  IconButton,
  Divider,
  Box,
  CircularProgress,
} from "@mui/material"
import { styled } from "@mui/material/styles"

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(20),
  height: theme.spacing(20),
  margin: "auto",
}))

const IconWrapper = styled("span")(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  color: theme.palette.success.main,
  marginRight: theme.spacing(1),
}))

const UserProfile = ({ translation }) => {
  const user = useSelector((state) => state.auth.user)
  const isLoading = useSelector((state) => state.auth.loading)
  const dispatch = useDispatch()

  const [isEditingProfilePicture, setIsEditingProfilePicture] = useState(false)
  const [isEditingDetails, setIsEditingDetails] = useState(false)

  const handleEditProfilePicture = () => {
    setIsEditingProfilePicture(true)
  }

  const handleEditPictureDone = () => {
    setIsEditingProfilePicture(false)
    dispatch(loadUser())
  }

  const toggleEditDetails = () => {
    setIsEditingDetails(!isEditingDetails)
  }

  const handleDeleteAccountRequest = async () => {
    try {
      const config = getAxiosConfig()
      await axios.post(`${process.env.REACT_APP_API_URL}/v1/delete-account-request/`, {}, config)
      toast.success(translation.delete_account_request_success)
    } catch (err) {
      toast.error(translation.delete_account_request_error)
    }
  }

  const GENDER_MAP = { m: translation.up_male, f: translation.up_female, o: translation.up_other }

  if (isLoading || !user) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    )
  }

  const {
    account: { first_name, last_name } = {},
    profile_picture,
    gender,
    phone_number,
    receive_new_event_notification,
  } = user ?? {}

  const countryName = user.country?.country_name ?? ""
  const cityName = user.city?.city_name ?? ""
  const genderFull = GENDER_MAP[gender]

  return (
    <Box sx={{ backgroundColor: "#eee", py: 4 }}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Box position="relative" display="inline-block">
                  <ProfileAvatar src={profile_picture} alt={`${first_name} ${last_name}`} />
                  <IconButton
                    onClick={handleEditProfilePicture}
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      right: 0,
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                      "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.9)" },
                    }}
                  >
                    <IoCameraReverse />
                  </IconButton>
                </Box>
                <Typography variant="h5" sx={{ mt: 2 }}>{`${first_name} ${last_name}`}</Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  <IconWrapper>
                    <IoLocationOutline />
                  </IconWrapper>
                  {`${countryName}, ${cityName}`}
                </Typography>
                {isEditingProfilePicture && <EditProfilePicture onUploadDone={handleEditPictureDone} />}
                <Button variant="outlined" onClick={toggleEditDetails} sx={{ mt: 2 }}>
                  {translation.up_edit}
                </Button>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            {isEditingDetails ? (
              <UserProfileEditForm onClose={toggleEditDetails} />
            ) : (
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle2">{translation.first_name}</Typography>
                      <Typography variant="body1" color="text.secondary">
                        {first_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle2">{translation.last_name}</Typography>
                      <Typography variant="body1" color="text.secondary">
                        {last_name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ my: 2 }} />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle2">{translation.country}</Typography>
                      <Typography variant="body1" color="text.secondary">
                        {countryName}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="subtitle2">{translation.city}</Typography>
                      <Typography variant="body1" color="text.secondary">
                        {cityName}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ my: 2 }} />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                      <Typography variant="subtitle2">{translation.gender}</Typography>
                      <Typography variant="body1" color="text.secondary">
                        {genderFull}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Typography variant="subtitle2">{translation.phone_number}</Typography>
                      <Typography variant="body1" color="text.secondary">
                        <IconWrapper>
                          <IoPhonePortraitOutline />
                        </IconWrapper>
                        {phone_number}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider sx={{ my: 2 }} />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                      <Typography variant="subtitle2">
                        <IconWrapper>
                          <IoNotificationsOutline />
                        </IconWrapper>
                        {translation.notifications}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Typography variant="body1" color="text.secondary">
                        {receive_new_event_notification ? translation.yes : translation.no}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
            <Box textAlign="center" mt={3}>
              <Button color="error" onClick={handleDeleteAccountRequest} sx={{ textDecoration: "underline" }}>
                {translation.delete_account}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  translation: state.appState.translation,
})

export default connect(mapStateToProps, null)(UserProfile)

