import {
  FETCH_GAME_CARDS_REQUEST,
  FETCH_GAME_CARDS_SUCCESS,
  FETCH_GAME_CARDS_FAILURE,
  UPDATE_MATCH_REQUEST,
  UPDATE_MATCH_SUCCESS,
  UPDATE_MATCH_FAILURE
} from '../actions/taActions';

const initialState = {
  gameCard: null,
  loading: false,
  error: null,
  updateLoading: false,
  updateError: null
};

const taStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GAME_CARDS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_GAME_CARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        gameCard: action.payload
      };
    case FETCH_GAME_CARDS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UPDATE_MATCH_REQUEST:
      return {
        ...state,
        updateLoading: true,
        updateError: null
      };
    case UPDATE_MATCH_SUCCESS:
      return {
        ...state,
        updateLoading: false
      };
    case UPDATE_MATCH_FAILURE:
      return {
        ...state,
        updateLoading: false,
        updateError: action.payload
      };
    default:
      return state;
  }
};

export default taStateReducer;

