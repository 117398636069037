export const translations = {
    ro: {
        login_title: 'Conecteaza-te, pescarule!',
        login: 'Autentificare',
        signup_title: 'Inregistreaza-te',
        signup_subtitle:'Creaza-ti un cont',
        login_subtitle: 'Introdu credentialele pentru a accesa contul',
        start_date: 'Data de start',
        end_date: 'End date',
        participation_category: 'Categoria',
        event_duration: 'Durata',
        hours: 'ore',
        details: 'Detalii',
        tournament: 'Turneu',
        events: 'Etape',
        event: 'Etapa',
        event_cta: 'Mergi la etapa!',
        sponsors: 'Sponsori',
        location: 'Locatie',
        location_details: 'Detalii locatie',
        fish_scoring: 'Punctaj',
        fish: 'Specie',
        name: 'Name',
        catch_slots: 'Sloturi',
        min_length: 'Lungime minima',
        under_length_points: 'Puncte sub limita',
        search: 'Cauta',
        logo: 'Logo',
        event_name: 'Nume Etapa',
        event_type: 'Tip Etapa',
        event_participation_type: 'Tipul de concurs',
        event_location: 'Locatie',
        participation_tax: 'Taxa de participare',
        event_location_details: 'Detalii locatie',
        event_duration_hours: 'Durata (ore)',
        next_step: 'Next step',
        principal_sponsors: 'Sponsori principali',
        other_sponsors: 'Alti sponsori',
        notification_join_club: 'Ați fost invitat să vă alăturați unui club',
        go_back: 'Go back',
        fishtypes: 'Specie',
        accountable_catch_slots: 'Sloturi punctabile',
        accountable_min_length: 'Lungimea minima',
        under_min_length_points: 'Puncte sub lungimea minima',
        addScoring: 'Add scoring',
        deleteScoring: 'Delete scoring',
        ongoing: 'ongoing',
        upcoming: 'upcoming',
        finished: 'finished',
        error_login: 'Nume de utilizator sau parolă incorectă!',
        prize_name: 'Premiu',
        event_total_amount: 'Total amount',
        prize_value: 'Valoare',
        deletePrizeSection: 'Sterge sectiune premii',
        addPrizeSection: 'Adauga sectiune premii',
        cta_goToMyEvents: 'Mergi la evenimentele mele',
        success_message: 'Ați creat un eveniment, vă rugăm să urmați linkul pentru a-l gestiona.' ,
        contact_us_title: 'Contacteaza-ne!',
        contact_us_subtitle: 'Ai intrebari sau sugestii! Completeaza formularul de mai jos pentru a ne contacta.',
        contact_us_placeholder: 'Mesajul tau aici...',
        contact_us_send: 'Trimite mesaj',
        contact_us_sending: 'Se trimite....',
        sponsors_link_text: 'Viziteaza sponsorii nostri',
        contact_email_placeholder: 'Adresa Email',
        contact_name_placeholder: 'Nume Prenume',
        edit_event: 'Modifica etapa',
        edit_fishscoring: 'Modifica scoring',
        edit_prizes: 'Modifica premii',
        view_map: 'Vezi harta capturi',
        my_events: 'Evenimentele mele',
        publish: 'Publica',
        published: 'Publicat',
        update_general: 'Update general',
        gender: 'Sex',
        first_name: 'Prenume',
        last_name : 'Nume',
        country: 'Tara',
        city: 'Oras',
        phone_number : 'Numar de telefon',
        notifications: 'Notificari',
        enroll: 'Mă înscriu',
        pending: 'Aprobare in asteptare',
        rejected: 'Respins',
        enrolled: 'Inscris',
        approved: 'Inscris',
        'not enrolled': 'Mă înscriu',
        topx: 'Sloturi punctabile',
        scoring_slots: 'Scoring numar sloturi',
        topxcatches: 'Top X Catches',
        event_logo: 'Upload banner',
        start_event: 'Start',
        stop_event: 'Stop',
        upload_fish: 'Încarcă captură',
        fish_type: 'Specie',
        validator: 'Validatori',
        event_location_city: 'Oras',
        password: 'Parola',
        confirm_password: 'Confirma parola',
        email: 'Introduceti adresa de e-mail',
        terms_and_conditions: 'Termeni si conditii',
        by_logging_in: 'Autentificându-vă, sunteți de acord cu ',
        register_cta: 'Înregistrare',
        password_forgotpassword: 'Ați uitat parola?',
        agree_terms: 'Sunt de accord cu ',
        already_account: 'Aveți deja un cont? ',
        sign_in: 'Autentificare',
        general: 'General',
        scoring: 'Punctaj',
        prizes: 'Premii',
        enrollments: 'Înscrieri',
        ranking: 'Clasament',
        no_ranking: 'Nu există încă un clasament, evenimentul nu s-a terminat',
        enroll_date: 'Data Înscrierii ',
        enroll_name: 'Nume',
        enroll_contact: 'Telefon',
        cls_rank: 'Pozitia',
        cls_points: 'Puncte',
        cls_total_species: 'Total specii',
        cls_total_caught: 'Total capturi',
        cls_average: 'Medie capturi',
        cls_cmmc: 'CMMC',
        cls_fish: 'Specie',
        cls_length: 'Lungime',
        cls_valid: 'Valid',
        cls_name: 'Nume',
        cls_status: 'Status',
        ong_upload: 'Încarcă captură',
        ong_fish_length: 'Lungime (cm)',
        ong_my_catch: 'Captura mea',
        ong_map: 'Harta',
        up_edit: 'Editeaza detalii',
        yes: 'Da',
        no: 'Nu',
        up_phone_format: 'Numarul de telefon trebuie sa fie in formatul +407xxxxxxxx',
        up_new_event_notification: 'Primeste notificari cand se incarca un event nou',
        up_select_gender: 'Alege...',
        up_select_country: 'Alege tara...',
        up_select_city: 'Alege orasul...',
        up_male: 'Masculin',
        up_female: 'Feminin',
        up_other: 'Altceva',
        up_invalid_phone: 'Formatul numărului de telefon este invalid. Numărul de telefon trebuie să înceapă cu un cod de țară, urmat de 6 până la 10 cifre.',
        up_save_changes: 'Salveaza',
        main_home: 'Acasă',
        main_logout: 'Deconectare',
        main_events: 'Evenimente',
        dash_go_to_event: 'Vezi',
        dash_go_to_upload: 'Încarcă captură',
        dash_performance: 'Performanță',
        dash_fish_caught: 'Număr capturi',
        dash_nb_participation: 'Număr de etape',
        dash_best_place: 'Cea mai bună poziție',
        dash_enroll_events: 'Evenimentele mele',
        dash_best_anglers: 'Top pescari (evenimente nationale)',
        dash_overall_score: 'Medie capturi',
        dash_validate_events: 'Evenimente de validat',
        dash_organized_events: 'Evenimente organizate',
        dash_total_catches: 'Total capturi',
        dash_year_events: 'Etape in anul curent',
        dash_no_events_to_show: 'Nu există evenimente de afișat.',
        dash_no_event_found: 'Nu s-au găsit evenimente.',
        dash_failed_to_show: 'Eroare la obținerea evenimentelor. Încercați din nou mai târziu.',
        dash_go_to_validate: 'Validează',
        ong_validated: 'Pesti validati',
        details_link: 'Acceseaza link',
        no_player: 'Numar concurent',
        other_photo: 'Capturi anterioare',
        biggest_catch: 'Cea mai mare captura',
        team_name: 'Echipe',
        species_count: 'Nr. Specii',
        bonus_points: 'Puncte Bonus',
        points: 'Puncte',
        team_number : 'Nr.',
        delete_account_title: "Cum să îți ștergi contul",
        delete_account_subtitle: "Te rugăm să urmezi instrucțiunile de mai jos pentru a-ți șterge contul:",
        delete_account_step_1: "Accesează pagina de profil din aplicație.",
        delete_account_step_2: "Găsește și apasă pe butonul 'Șterge Cont'.",
        delete_account_step_3: "Vei primi un email de confirmare după ce solicitarea ta este procesată.",
        delete_account: "Șterge cont",
        delete_account_request_success: "Cererea ta de ștergere a contului a fost trimisă cu succes.",
        delete_account_request_error: "A apărut o eroare la trimiterea cererii. Te rugăm să încerci din nou mai târziu.",
        stats: "Statistici",
        no_stats: "Statisticile vor fi disponibile la finalul evenimentului",
        no_stats_available: "Statisticile vor fi disponibile la finalul evenimentului",
        loading: "Se incarca....",
        event_statistics: "Statistici ale evenimentului",
        basic_statistics: "Statistici de bază",
        species_statistics: "Statistici specii",
        hourly_captures: "Capturi pe oră",
        captured_species_details: "Detalii specii capturate",
        number_of_competitors: "Numărul de concurenți",
        total_catches: "Total capturi",
        average_catches_per_competitor: "Media capturilor pe concurent",
        average_catches_per_hour: "Media capturilor pe oră",
        total_length_of_catches: "Lungimea totală a capturilor",
        average_length_of_catches: "Lungimea medie a capturilor",
        number_of_scoreable_species: "Numărul de specii punctabile",
        number_of_captured_species: "Numărul de specii capturate",
        captures_over_limit: "Capturi peste limită",
        captures_under_limit: "Capturi sub limită",
        hour: "Ora",
        count: "Număr",
        percentage: "Procentaj",
        species: "Specie",
        over_limit: "Peste limită",
        under_limit: "Sub limită",
        statistic: "Statistică",
        value: "Valoare",
        reset_password_title: "Schimbare parola de autentificare",
        reset_password_subtitle : "Introdu noua parola",
        reset_password_button: "Reseteaza",
        confirm_new_password: "Confirma noua parola",
        passwords_do_not_match: "Parolele nu se potrivesc",
        password_reset_successful: "Parola schimbata cu succes.",
        password_reset_failed: "Eroare schimbare parola.",
        contestatie: 'Contestatie',
        contestatii: 'Contestatii',
        generate_lineup: 'Genereaza lineup',
        generating_matches: 'Generare carduri...',
        generate_matches: 'Genereaza carduri',
        generating_lineup: 'Generare lineup..',
        download_lineup: 'Descarca lineup',
        exists: "Exista",
        captured_species_distribution : "Distribuția speciilor capturate",
        hourly_captures_details:"Detalii despre capturile orare",
        actions:"Actiuni",
        confirm_action: "Confirma actiunea",
        cancel:"Nu",
        confirm:"Da",
        confirm_start_event:"Confirma Start etapa",
        confirm_stop_event:"Confirma Stop etapa",
        download_organizer_photos: "Exporta Pozele Etapei",
        export_scoreboard : "Exporta Clasamentul Etapei",
        export_summary_csv : "Exporta Statistica Etapei",
        scoreboard_exported:"Scoreboard exportat cu success.",
        export_failed:"Eroare export.",
        downloading_photos:"Descarcare poze...",
        exporting_scoreboard:"Descarcare scoreboard..",
        exporting_summary:"Descarcare stats..",
        summary_exported:"Stats Exported",
        photos_downloaded:"Download finished",
        download_failed:"Download failed",
        no_actions: "No actions for you",
        scoring_type: "Tip Eveniment",
        is_national_event: "Eveniment Național",
        allow_gallery_photos: "Permite Fotografii din Galerie",
        Type_1: "Scoring număr sloturi per specie",
        Type_2: "TA - Trout Area",
        Type_3: "MAL - Pescuit la păstrăv pe sălbatic",
        Top_X_Catches: "Top X număr capturi",
        Free_Type: "Neimplementat",
        scoring_type_reminder: "Tip eveniment",
},

    en: {
        login_title: 'Sign in, angler!',
        login: 'Login',
        login_subtitle: 'Enter your login credentials to access your account',
        signup_title: 'Sign Up',
        start_date: 'Start date',
        end_date: 'End date',
        participation_category: 'Participation category',
        event_duration: 'Duration',
        hours: 'hours',
        details: 'Details',
        tournament: 'Tournament',
        events: 'Events',
        event: 'Event',
        event_cta: 'Go to event!',
        sponsors: 'Sponsors',
        location: 'Location',
        location_details: 'Location details',
        fish_scoring: 'Fish scoring',
        fish: 'Species',
        name: 'Name',
        catch_slots: 'Catch slots',
        min_length: 'Min. length',
        under_length_points: 'Under length points',
        search: 'Search',
        logo: 'Logo',
        event_name: 'Event Name',
        event_type: 'Event type',
        event_participation_type: 'Participation type',
        event_location: 'Location',
        participation_tax: 'Participation fee',
        event_location_details: 'Location details',
        event_duration_hours: 'Duration (hours)',
        next_step: 'Next step',
        principal_sponsors: 'Principal sponsor',
        other_sponsors: 'Other sponsors',
        notification_join_club: 'If you have been invited to join a club.',
        go_back: 'Go back',
        fishtypes: 'Pește',
        accountable_catch_slots: 'Sloturi punctabile',
        accountable_min_length: 'Lungimea minima',
        under_min_length_points: 'Puncte sub lungimea minima',
        addScoring: 'Add scoring',
        deleteScoring: 'Delete scoring',
        ongoing: 'ongoing',
        upcoming: 'upcoming',
        finished: 'finished',
        error_login: 'Incorrect username or password!',
        prize_name: 'Prize',
        event_total_amount: 'Total amount',
        prize_value: 'Value',
        deletePrizeSection: 'Delete prize section',
        addPrizeSection: 'Add price section',
        cta_goToMyEvents: 'Go to my events',
        success_message: 'You have created an event, please follow the link to manage it.',
        contact_us_title: 'Contact Us!',
        contact_us_subtitle: 'Have questions or feedback? Fill out the form below to contact us.',
        contact_us_placeholder: 'Your message here...',
        contact_us_send: 'Send Message',
        contact_us_sending: 'Sending....',
        sponsors_link_text: 'Visit our sponsors',
        contact_email_placeholder: 'Email address',
        contact_name_placeholder: 'Full Name',
        edit_event: 'Edit event',
        edit_fishscoring: 'Edit fish scoring',
        edit_prizes: 'Edit Prizes',
        view_map: 'View map',
        my_events: 'My events',
        publish: 'Publish',
        published: 'Published',
        update_general: 'Update general',
        gender: 'Gender',
        first_name: 'First Name',
        last_name : 'Last Name',
        country: 'Country',
        city: 'City',
        phone_number : 'Phone Number',
        notifications: 'Notifications',
        enroll: 'Enroll',
        pending: 'Pending',
        rejected: 'Rejected',
        enrolled: 'Enrolled',
        approved: 'Enrolled',
        'not enrolled': 'Enroll',
        topx: 'Sloturi punctabile',
        scoring_slots: 'Scoring based on catch slots',
        topxcatches: 'Top X Catches',
        event_logo: 'Upload banner',
        start_event: 'Start',
        stop_event: 'Stop',
        upload_fish: 'Upload catch',
        fish_type: 'Species',
        validator: 'Validator',
        event_location_city: 'Oras',
        password: 'Password',
        email: 'Email',
        terms_and_conditions: 'Terms and Conditions',
        by_logging_in: ' By logging in, you agree to our ',
        register_cta: 'Register',
        password_forgotpassword: 'Forgot password?',
        agree_terms: 'I agree to the ',
        already_account: 'Already have an account? ',
        sign_in: 'Sign in',
        general: 'General',
        scoring: 'Scoring',
        prizes: 'Prizes',
        enrollments: 'Enrollments',
        ranking: 'Ranking',
        no_ranking: 'No ranking yet, event hasn\'t finished',
        enroll_date: 'Enroll date',
        enroll_name: 'Name',
        enroll_contact: 'Phone',
        cls_rank: 'Rank',
        cls_points: 'Points',
        cls_total_species: 'Total species',
        cls_total_caught: 'Total catches',
        cls_average: 'Average catches',
        cls_cmmc: 'BC',
        cls_fish: 'Species',
        cls_length: 'Length',
        cls_valid: 'Valid',
        cls_name: 'Name',
        cls_status: 'Status',
        ong_upload: 'Upload catch',
        ong_fish_length: 'Fish Length (cm)',
        ong_my_catch: 'My catch',
        ong_map: 'Map',
        up_edit: 'Edit details',
        yes: 'Yes',
        no: 'No',
        up_phone_format: 'Phone number must be in the format +407xxxxxxxx',
        up_new_event_notification: 'Receive New Event Notifications',
        up_select_gender: 'Select Gender...',
        up_select_country: 'Select country...',
        up_select_city: 'Select city...',
        up_male: 'Male',
        up_female: 'Female',
        up_other: 'Other',
        up_invalid_phone: 'Invalid phone number format. Phone number must start with a country code, followed by 6 to 10 digits',
        up_save_changes: 'Save changes',
        main_home: 'Home',
        main_logout: 'Logout',
        main_events: 'Events',
        dash_go_to_event: 'View',
        dash_go_to_upload: 'Upload catch',
        dash_performance: 'Performance',
        dash_fish_caught: 'Number of Catches',
        dash_nb_participation: 'Number of Participations',
        dash_best_place: 'Best Placement',
        dash_enroll_events: 'Enrolled Events',
        dash_best_anglers: 'Top Anglers (national events)',
        dash_overall_score: 'Overall Score',
        dash_validate_events: 'Events to Validate',
        dash_organized_events: 'Organized Events',
        dash_total_catches: 'Total Catches',
        dash_year_events: 'Current Year Events',
        dash_no_events_to_show: 'No events to show.',
        dash_no_event_found: 'No events found.',
        dash_failed_to_show: 'Failed to get events. Try again later.',
        dash_go_to_validate: 'Validate',
        ong_validated: 'Validated fish',
        details_link: 'Go to link',
        no_player: 'Numar concurent',
        other_photo: 'Capturi anterioare',
        biggest_catch: 'Cea mai mare captura',
        team_name: 'Teams',
        species_count: 'Nb. Species',
        bonus_points: 'Bonus Points',
        points: 'Points',
        team_number : 'No.',
        delete_account_title: "How to Delete Your Account",
        delete_account_subtitle: "Please follow the instructions below to delete your account:",
        delete_account_step_1: "Go to your Profile page in the app.",
        delete_account_step_2: "Find and click the 'Delete Account' button.",
        delete_account_step_3: "You will receive an email confirmation once your request is processed.",
        delete_account: "Delete account",
        delete_account_request_success: "Your delete account request has been submitted successfully.",
        delete_account_request_error: "There was an error submitting your request. Please try again later.",
        stats: "Stats",
        no_stats: "Statistics are not available until the event has ended.",
        no_stats_available: "Statistics are not available until the event has ended.",
        event_statistics: "Event Statistics",
        fetch_error: "Failed to fetch event statistics.",
        loading: "Loading...",
        basic_statistics: "Basic Statistics",
        species_statistics: "Species Statistics",
        hourly_captures: "Hourly Captures",
        captured_species_details: "Captured Species Details",
        number_of_competitors: "Number of Competitors",
        total_catches: "Total Catches",
        average_catches_per_competitor: "Average Catches per Competitor",
        average_catches_per_hour: "Average Catches per Hour",
        total_length_of_catches: "Total Length of Catches",
        average_length_of_catches: "Average Length of Catches",
        number_of_scoreable_species: "Number of Scoreable Species",
        number_of_captured_species: "Number of Captured Species",
        captures_over_limit: "Captures Over Limit",
        captures_under_limit: "Captures Under Limit",
        hour: "Hour",
        count: "Count",
        percentage: "Percentage",
        species: "Species",
        over_limit: "Over Limit",
        under_limit: "Under Limit",
        statistic: "Statistics",
        value: "Value",
        reset_password_title: "Schimbare parola",
        reset_password_subtitle : "Introdu noua parola",
        reset_password_button: "Reseteaza",
        confirm_new_password: "Confirma noua parola",
        passwords_do_not_match: "Parolele nu se potrivesc",
        password_reset_successful: "Parola schimbata cu succes.",
        password_reset_failed: "Eroare schimbare parola.",
        contestatie: 'Contestatie',
        contestatii: 'Contestatii',
        generate_lineup: 'Create lineup',
        generating_matches: 'Creating game cards',
        generate_matches: 'Create game cards',
        generating_lineup: 'Creating the lineup..',
        download_lineup: 'Download lineup',
        exists: "Exists",
        captured_species_distribution : "Captured Species Distribution",
        hourly_captures_details:"Hourly Captures Details",
        actions:"Actions",
        confirm_action: "Confirm action",
        cancel:"No",
        confirm:"Yes",
        confirm_start_event:"Please confirm to Start",
        confirm_stop_event:"Please confirm to Stop",
        download_organizer_photos: "Export Event Photos",
        export_scoreboard : "Export Event Scoreboard",
        export_summary_csv : "Export Event Stats",
        scoreboard_exported:"Scoreboard exported.",
        export_failed:"Failed to export",
        downloading_photos:"Downloading photos...",
        exporting_scoreboard:"Downloading scoreboard..",
        exporting_summary:"Downloading stats..",
        summary_exported:"Stats Exported",
        photos_downloaded:"Download finished",
        download_failed:"Download failed",
        no_actions: "No actions for you",
        scoring_type: "Event Type",
        is_national_event: "National Event",
        allow_gallery_photos: "Allow Gallery Photos",
        Type_1: "Scoring by number of slots by species",
        Type_2: "TA - Trout Area",
        Type_3: "Not implemented - MAL - Wild trout fishing",
        Top_X_Catches: "Scoring by TOP number of slots",
        Free_Type: "Not implemented",
        scoring_type_reminder: "Scoring type",

    }
}
