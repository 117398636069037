import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IoFishSharp, IoRibbonSharp, IoPeopleSharp, IoCalendarOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import getAxiosConfig from '../utils/axiosConfig';

const Dashboard = ({ menuOpen, user, locations, translation, performance, topAnglers  }) => {
    const navigate = useNavigate();
    const [enrolledEvents, setEnrolledEvents] = useState(null);
    const [enrolledEventsError, setEnrolledEventsError] = useState(null);
    const [organizedEvents, setOrganizedEvents] = useState(null);
    const [organizedEventsError, setOrganizedEventsError] = useState(null);
    const [validateEvents, setValidateEvents] = useState(null);
    const [validateEventsError, setValidateEventsError] = useState(null);

    useEffect(() => {
        async function fetchEnroll() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/event/enrolled-events/`, getAxiosConfig());
                setEnrolledEvents(response.data.events);
            } catch(e) {
                setEnrolledEventsError(e)
            }
        }

        async function fetchOrganize() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/event/organized-events/`, getAxiosConfig());
                setOrganizedEvents(response.data.events);
            } catch(e) {
                setOrganizedEventsError(e);
            }
        }

        async function fetchValidate() {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/event/validate-events/`, getAxiosConfig());
                setValidateEvents(response.data.events);
            } catch(e) {
                setValidateEventsError(e);
            }
        }

        fetchEnroll();
        fetchOrganize();
        fetchValidate();
    }, []);

    return (
    <>
        <div className={`page-container container-fluid`}>
            <div className='row g-3 w-100'>
                <div className="d-flex mb-3 card p-3">
                    <h6 className='w-100 text-start m-0 border-bottom'>{translation.dash_performance}</h6>
                    <div className="row m-0">
                        <div className='card-info flex-fill py-3 position-relative ps-5 col-xs-12 col-sm-4'>
                            <p className='m-0 fw-bold text-start'>{translation.dash_fish_caught}</p>
                            <p className='my-1 fs-1 fw-bold text-start'>{performance?.total_catches}</p>
                            <p className='m-0 text-start fw-lighter'>{translation.biggest_catch}: <span>{performance?.biggest_catch} cm</span></p>
                            <IoFishSharp className='card-info-icon position-absolute start-0 top-50 fs-1 rounded-circle p-1 color-blue translate-middle-y'/>
                        </div>
                        <div className='card-info flex-fill py-3 position-relative ps-5 col-xs-12 col-sm-4'>
                            <p className='m-0 fw-bold text-start'>{translation.dash_nb_participation}</p>
                            <p className='my-1 fs-1 fw-bold text-start'>{performance?.number_of_participations}</p>
                            <p className='m-0 text-start fw-lighter'>{translation.dash_year_events}: <span>{performance?.current_year_participations}</span></p>
                            <IoRibbonSharp className='card-info-icon position-absolute start-0 top-50 fs-1 rounded-circle p-1 color-green translate-middle-y'/>
                        </div>
                        <div className='card-info flex-fill py-3 position-relative ps-5 col-xs-12 col-sm-4'>
                            <p className='m-0 fw-bold text-start'>{translation.dash_best_place}</p>
                            <p className='my-1 fs-1 fw-bold text-start'>{performance?.best_placement}</p>
                            <IoPeopleSharp className='card-info-icon position-absolute start-0 top-50 fs-1 rounded-circle p-1 color-red translate-middle-y'/>
                        </div>
                    </div>
                </div>
                <div className="d-flex p-0 m-0 flex-md-row flex-column">
                    <div className="card p-3 col-12 mb-3 dashboard-event-card">
                        <h6 className='w-100 text-start m-0 border-bottom pb-2'>
                            {translation.dash_enroll_events}
                        </h6>
                        <table className="table text-start">
                            <thead>
                                <tr>
                                    <th scope="col">{translation.events}</th>
                                    <th scope="col">{translation.location_details}</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {enrolledEvents?.map((item) => (
                                    <tr key={item.id}>
                                        <th scope="row">{item.event_name}</th>
                                        <td>{item.event_location_details}</td>
                                        <td className='text-end'>
                                            {item.is_ongoing ? (
                                                <button
                                                    type="submit"
                                                    className="btn green text-white submit-button"
                                                    onClick={(e) => {
                                                        if (item.scoring_type === 'Type_2') {
                                                            navigate(`/taeventongoing/${item.id}`);
                                                        } else {
                                                            navigate(`/eventongoing/${item.id}`);
                                                        }
                                                    }}
                                                >
                                                    {translation.dash_go_to_upload}
                                                </button>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className="btn green text-white submit-button"
                                                    onClick={(e) => navigate(`event/${item.id}`)}
                                                >
                                                    {translation.dash_go_to_event}
                                                </button>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                                {!enrolledEvents && !enrolledEventsError && (
                                    <tr className='fw-bold text-center pt-2 pb-0'><IoCalendarOutline role="button"
                                                                                                     className='color-blue fs-1'/> {translation.dash_no_event_found}
                                    </tr>
                                )}
                                {!enrolledEvents && enrolledEventsError && (
                                    <tr className='fw-bold text-center pt-2 pb-0'><IoCalendarOutline role="button"
                                                                                                     className='color-blue fs-1'/> {translation.dash_no_events_to_show}</tr>
                                )}
                                {enrolledEvents && enrolledEvents.length === 0 && (
                                    <tr className='fw-bold text-center pt-2 pb-0'><IoCalendarOutline role="button" className='color-blue fs-1' /> {translation.dash_no_event_found}</tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>


                <div className="d-flex p-0 m-0 flex-md-row flex-column">
                    {user?.is_organiser && organizedEvents && (
                        <div className="card p-3 col-12 mb-3 dashboard-event-card">
                            <h6 className='w-100 text-start m-0 border-bottom pb-2'>
                                {translation.dash_organized_events}
                            </h6>
                            <table className="table text-start">
                                <thead>
                                    <tr>
                                        <th scope="col ">{translation.events}</th>
                                        <th scope="col">{translation.location_details}</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {organizedEvents?.map((item) => (
                                        <tr key={item.id}>
                                            <th scope="row">{item.event_name}</th>
                                            <td>{item.event_location_details}</td>
                                            <td className='text-end'>
                                                <button type="submit" className="btn green text-white submit-button" onClick={(e) => navigate(`event/${item.id}`)}>
                                                    {translation.dash_go_to_event}
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    {!organizedEvents && !organizedEventsError && (
                                        <tr className='fw-bold text-center pt-2 pb-0'><IoCalendarOutline role="button" className='color-blue fs-1' /> {translation.dash_no_event_found}</tr>
                                    )}
                                    {!organizedEvents && organizedEventsError && (
                                        <tr className='fw-bold text-center pt-2 pb-0'><IoCalendarOutline role="button" className='color-blue fs-1' /> {translation.dash_failed_to_show}</tr>
                                    )}
                                    {organizedEvents && organizedEvents.length === 0 && (
                                        <tr className='fw-bold text-center pt-2 pb-0'><IoCalendarOutline role="button" className='color-blue fs-1' /> {translation.dash_no_events_to_show}</tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}

                    {user?.is_validator && validateEvents && (
                        <div className="card p-3 col-12 mb-3 dashboard-event-card">
                            <h6 className='w-100 text-start m-0 border-bottom pb-2'>
                                {translation.dash_validate_events}
                            </h6>
                            <table className="table text-start">
                                <thead>
                                    <tr>
                                        <th scope="col ">{translation.events}</th>
                                        <th scope="col">{translation.location_details}</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {validateEvents?.map((item) => (
                                        <tr key={item.id}>
                                            <th scope="row">{item.event_name}</th>
                                            <td>{item.event_location_details}</td>
                                            <td className='text-end'>
                                                <button type="submit" className="btn green text-white submit-button" onClick={(e) => navigate(`/validate/${item.id}`)}>
                                                    {translation.dash_go_to_validate}
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    {!validateEvents && !validateEventsError && (
                                        <tr className='fw-bold text-center pt-2 pb-0'><IoCalendarOutline role="button" className='color-blue fs-1' /> {translation.dash_no_events_to_show}</tr>
                                    )}
                                    {!validateEvents && !validateEventsError && (
                                        <tr className='fw-bold text-center pt-2 pb-0'><IoCalendarOutline role="button" className='color-blue fs-1' /> {translation.dash_no_events_to_show}</tr>
                                    )}
                                    {validateEvents && validateEvents.length === 0 && (
                                        <tr className='fw-bold text-center pt-2 pb-0'><IoCalendarOutline role="button" className='color-blue fs-1' /> {translation.dash_no_event_found}</tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}

                </div>

                <div className="d-flex mb-3 card p-3">
                    <h6 className='w-100 text-start m-0 border-bottom'>
                        {translation.dash_best_anglers}
                    </h6>
                    <div className="d-flex w-100">
                        <table className="table text-start">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">{translation.name}</th>
                                    <th scope="col">{translation.dash_nb_participation}</th>
                                    <th scope="col">{translation.dash_overall_score}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {topAnglers.map((item, index) => (
                                    <tr key={`top_${item.rank}`}><td className='fw-bold'>{index + 1}</td><td>{item.name}</td><td>{item.participations}</td><td>{item.overall_score}</td></tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* <div className="d-flex mb-3 card p-3">
                    <h6 className='w-100 text-start m-0 border-bottom'>
                        {translation.location_details}
                    </h6>
                    <div className="d-flex w-100 pt-3">
                        <div className="card-info-map w-75">
                            <img className='img-responsive' src='./romania_map.jpg' alt='logo'/>
                        </div>
                        <div className="card-info-map w-25 d-flex flex-column">
                            <div className='card-location flex-grow-1 d-flex align-items-center'><p className='w-100 m-0 fw-bold fs-5'>Lacul Herăstrău</p></div>
                            <div className='card-location flex-grow-1 d-flex align-items-center'><p className='w-100 m-0 fw-bold fs-5'>Râul Someșul Mic</p></div>
                            <div className='card-location flex-grow-1 d-flex align-items-center'><p className='w-100 m-0 fw-bold fs-5'>Râul Dâmbovița</p></div>
                            <div className='card-location flex-grow-1 d-flex align-items-center'><p className='w-100 m-0 fw-bold fs-5'>Lacul Morii</p></div>
                            <div className='card-location flex-grow-1 d-flex align-items-center'><p className='w-100 m-0 fw-bold fs-5'>Râul Mureș</p></div>
                        </div>

                    </div>
                </div> */}
            </div>

        </div>
    </>
)}

const mapStateToProps = state => ({
    menuOpen: state.appState.menuOpen,
    user: state.auth.user,
    locations: state.utilsState.locations,
    performance: state.utilsState.performance,
    topAnglers: state.utilsState.topAnglers,
    translation: state.appState.translation,

});

export default connect(mapStateToProps, null)(Dashboard);

