import React, { useEffect, useState } from "react"
import axios from "axios"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import {
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { Pie } from "react-chartjs-2"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import getAxiosConfig from "../utils/axiosConfig"

ChartJS.register(ArcElement, Tooltip, Legend)

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: "100%",
}))

const YellowTableCell = styled(TableCell)(() => ({
  backgroundColor: "#ffc107",
  color: "white",
  fontWeight: "bold",
}))

const EventStats = ({ eventId, translation, user }) => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!eventId) return

    const fetchEventSummary = async () => {
      setLoading(true)
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v3/event/${eventId}/summary/`,
          getAxiosConfig(),
        )
        setData(response.data)
      } catch (error) {
        console.error("Error fetching event summary", error)
        toast.error(translation.fetch_error)
      } finally {
        setLoading(false)
      }
    }
    fetchEventSummary()
  }, [eventId, translation])

  if (loading) {
    return (
      <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
        <CircularProgress />
      </Container>
    )
  }

  if (!data) {
    return (
      <Container>
        <div className="galben text-white p-2 mb-3">{translation.no_stats_available}</div>
      </Container>
    )
  }

  const pieChartData = {
    labels: Object.keys(data.captured_species_details),
    datasets: [
      {
        data: Object.values(data.captured_species_details).map((species) => species.over_limit + species.under_limit),
        backgroundColor: [
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  }

  const pieChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: translation.captured_species_distribution || 'Captured Species',
      },
    },
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <div className="galben text-white p-2 mb-3">{translation.event_statistics}</div>

      <Grid container spacing={3}>
        {/* Basic Statistics */}
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <div className="galben text-white p-2 mb-3">{translation.basic_statistics}</div>
            <Table>
              <TableBody>
                {[
                  { label: translation.number_of_competitors, value: data.number_of_competitors },
                  { label: translation.total_catches, value: data.total_catches },
                  {
                    label: translation.average_catches_per_competitor,
                    value: data.average_catches_per_competitor.toFixed(2),
                  },
                  { label: translation.average_catches_per_hour, value: data.average_catches_per_hour.toFixed(2) },
                  { label: translation.total_length_of_catches, value: `${data.total_length_of_catches} cm` },
                  {
                    label: translation.average_length_of_catches,
                    value: `${data.average_length_of_catches.toFixed(2)} cm`,
                  },
                ].map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.label}</TableCell>
                    <TableCell align="right">{item.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledPaper>
        </Grid>

        {/* Species Statistics */}
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <div className="galben text-white p-2 mb-3">{translation.species_statistics}</div>
            <Table>
              <TableBody>
                {[
                  { label: translation.number_of_scoreable_species, value: data.number_of_scoreable_species },
                  { label: translation.number_of_captured_species, value: data.number_of_captured_species },
                  { label: translation.captures_over_limit, value: data.captures_over_limit },
                  { label: translation.captures_under_limit, value: data.captures_under_limit },
                ].map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.label}</TableCell>
                    <TableCell align="right">{item.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledPaper>
        </Grid>

        {/* Captured Species Pie Chart */}
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <div className="galben text-white p-2 mb-3">{translation.captured_species_distribution}</div>
            <div style={{ height: "300px" }}>
              <Pie data={pieChartData} options={pieChartOptions} />
            </div>
          </StyledPaper>
        </Grid>

        {/* Hourly Captures Table */}
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <div className="galben text-white p-2 mb-3">{translation.hourly_captures_details}</div>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <YellowTableCell>{translation.hour}</YellowTableCell>
                    <YellowTableCell align="right">{translation.count}</YellowTableCell>
                    <YellowTableCell align="right">{translation.percentage}</YellowTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.hourly_captures.map((item) => (
                    <TableRow key={item.hour}>
                      <TableCell>{`${translation.hour} ${item.hour}`}</TableCell>
                      <TableCell align="right">{item.count}</TableCell>
                      <TableCell align="right">{item.percentage.toFixed(2)}%</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledPaper>
        </Grid>

        {/* Captured Species Details */}
        <Grid item xs={12}>
          <StyledPaper>
            <div className="galben text-white p-2 mb-3">{translation.captured_species_details}</div>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <YellowTableCell>{translation.species}</YellowTableCell>
                    <YellowTableCell align="right">{translation.over_limit}</YellowTableCell>
                    <YellowTableCell align="right">{translation.under_limit}</YellowTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(data.captured_species_details).map(([species, counts]) => (
                    <TableRow key={species}>
                      <TableCell component="th" scope="row">
                        {species}
                      </TableCell>
                      <TableCell align="right">{counts.over_limit}</TableCell>
                      <TableCell align="right">{counts.under_limit}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </StyledPaper>
        </Grid>
      </Grid>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  translation: state.appState.translation,
})

export default connect(mapStateToProps)(EventStats)

