import React, { useEffect, useState, useCallback } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import Linkify from "react-linkify"

import { formatDate } from "../helpers/utils"
import { loadEvent, loadEnrollments, loadScoring, loadContestatii } from "../actions/eventsState"
import { createTALineup, generateMatches, checkLineupMatchExists, downloadLineup } from "../actions/taActions"
import EventActions from "../components/EventActions"
import Modal from "../components/Modal"
import EnrollmentsTable from "../components/EnrollmentsTable"
import FishPrizesTable from "../components/FishPrizesTable"
import ScoringTable from "../components/ScoringTable"
import Empty from "../components/Empty"
import MyEventCatchMap from "./MyCatchMap"
import { Helmet } from "react-helmet"
import { IoCameraOutline, IoPeopleOutline } from "react-icons/io5"
import EditEventBanner from "../components/EditEventBanner"
import axios from "axios"
import getAxiosConfig from "../utils/axiosConfig"
import { IoCaretDownOutline } from "react-icons/io5"
import Tabs from "../components/Tabs"
import EventStats from "../components/EventStats"
import ReportsTable from "../components/tables/ReportsTable"
import FishScoringTable from "../components/FishScoringTable"
import SponsorsCard from "../components/SponsorsCard"
import RankingMovement from "../components/RankingMovement"

const Event = ({
  event,
  translation,
  sponsors,
  fish_types,
  loadEvent,
  eventLoading,
  user,
  fishingspots,
  locations,
  scoring,
  contestatii,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { eventId } = useParams()
  const [selectedTab, setSelectedTab] = useState(0)
  const [openEventBanner, setOpenEventBanner] = useState(false)
  const [open, setOpen] = useState(false)
  const enrollments = useSelector((state) => state.eventState.enrollments)

  const [teamsData, setTeamsData] = useState(null)
  const [isTeamsExpanded, setIsTeamsExpanded] = useState(false)
  const [expandedTeam, setExpandedTeam] = useState(null)

  const [bonusPointsData, setBonusPointsData] = useState(null)
  const [isBonusExpanded, setIsBonusExpanded] = useState(false)

  const [isGeneratingLineup, setIsGeneratingLineup] = useState(false)
  const [isGeneratingMatches, setIsGeneratingMatches] = useState(false)
  const [lineupMatchExists, setLineupMatchExists] = useState({ lineup: false, match: false })


  const handleGenerateLineup = useCallback(async () => {
    if (isGeneratingLineup) return

    setIsGeneratingLineup(true)
    try {
      const result = await dispatch(createTALineup(eventId))
      if (result.success) {
        toast.success(result.message || translation.lineup_generated_success)
        checkLineupMatchStatus()
      } else {
        toast.error(result.error || translation.lineup_generation_failed)
      }
    } catch (error) {
      console.error("Error generating lineup", error)
      toast.error(translation.lineup_generation_failed)
    } finally {
      setIsGeneratingLineup(false)
    }
  }, [dispatch, eventId, translation, isGeneratingLineup])

  const handleGenerateMatches = useCallback(async () => {
    if (isGeneratingMatches) return

    setIsGeneratingMatches(true)
    try {
      const result = await dispatch(generateMatches(eventId))
      if (result.success) {
        toast.success(result.message || translation.matches_generated_success)
        checkLineupMatchStatus()
      } else {
        toast.error(result.error || translation.matches_generation_failed)
      }
    } catch (error) {
      console.error("Error generating matches", error)
      toast.error(translation.matches_generation_failed)
    } finally {
      setIsGeneratingMatches(false)
    }
  }, [dispatch, eventId, translation, isGeneratingMatches])

  const checkLineupMatchStatus = useCallback(async () => {
    try {
      const result = await dispatch(checkLineupMatchExists(eventId))
      if (result.success) {
        setLineupMatchExists(result.data)
      } else {
        console.error("Error checking lineup/match status:", result.error)
      }
    } catch (error) {
      console.error("Error checking lineup/match status:", error)
    }
  }, [dispatch, eventId])

  useEffect(() => {
    if (!eventId) {
      navigate("/")
    }

    loadEvent(eventId)
    dispatch(loadEnrollments(eventId))
    dispatch(loadScoring(eventId))
    dispatch(loadContestatii(eventId))
    checkLineupMatchStatus()
  }, [loadEvent, eventId, navigate, dispatch, checkLineupMatchStatus])

  const refreshEvent = useCallback(() => {
    loadEvent(eventId)
    dispatch(loadEnrollments(eventId))
  }, [loadEvent, eventId, dispatch])

  const refreshReports = useCallback(() => {
    dispatch(loadContestatii(eventId))
  }, [dispatch, eventId])

  const handleTeamButtonClick = async () => {
    if (!isTeamsExpanded) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/v2/event/team-members/`, {
          params: { event_id: eventId },
          ...getAxiosConfig(),
        })
        setTeamsData(response.data.results)
      } catch (error) {
        console.error("Error fetching team data", error)
        toast.error("Failed to fetch team data")
      }
    }
    setIsTeamsExpanded(!isTeamsExpanded)
  }

  const handleBonusPointsButtonClick = async () => {
    if (!isBonusExpanded) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v2/event-species-bonus/${eventId}/`,
          getAxiosConfig(),
        )
        setBonusPointsData(response.data.results)
      } catch (error) {
        console.error("Error fetching bonus points data", error)
        toast.error("Failed to fetch bonus points data")
      }
    }
    setIsBonusExpanded(!isBonusExpanded)
  }

  const handleDownloadLineup = useCallback(async () => {
    try {
      const result = await dispatch(downloadLineup(eventId, event?.event_name, event?.start_date))
      if (result.success) {
        toast.success(result.message || translation.lineup_downloaded_success)
      } else {
        toast.error(result.error || translation.lineup_download_failed)
      }
    } catch (error) {
      console.error("Error downloading lineup", error)
      toast.error(translation.lineup_download_failed)
    }
  }, [dispatch, eventId, event?.event_name, event?.start_date, translation])

  const renderBonusPoints = () => {
    if (!bonusPointsData) return null

    return (
      <table className="table table-hover w-100 mt-3">
        <thead>
          <tr className="galben text-white">
            <th>{translation.species_count}</th>
            <th>{translation.points}</th>
          </tr>
        </thead>
        <tbody>
          {bonusPointsData.map((bonusPoint) => (
            <tr key={bonusPoint.id}>
              <td>{bonusPoint.species_count}</td>
              <td>{bonusPoint.bonus_points}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  const renderTeams = () => {
    if (!teamsData) return null

    const groupedTeams = teamsData.reduce((acc, member) => {
      if (!acc[member.team_id]) {
        acc[member.team_id] = {
          team_name: member.team_name,
          team_number: member.team_number,
          members: [],
        }
      }
      acc[member.team_id].members.push(member.enrollment.user_full_name)
      return acc
    }, {})

    const toggleExpand = (teamId) => {
      setExpandedTeam(expandedTeam === teamId ? null : teamId)
    }

    return (
      <table className="table team-table mt-3">
        <thead>
          <tr className="table-header">
            <th></th>
            <th>{translation.team_number}</th>
            <th>{translation.team_name}</th>
            <th>{translation.details}</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(groupedTeams).map((teamId) => (
            <React.Fragment key={teamId}>
              <tr onClick={() => toggleExpand(teamId)} className="team-row">
                <td>
                  <IoCaretDownOutline
                    className={`color-green expand-icon ${expandedTeam === teamId ? "rotate-180" : ""}`}
                    role="button"
                    size={24}
                  />
                </td>
                <td className="team-name">{groupedTeams[teamId].team_number}</td>
                <td className="team-name">{groupedTeams[teamId].team_name}</td>
                <td className="team-details">
                  {groupedTeams[teamId].members.length} <IoPeopleOutline size={20} className="people-icon" />
                </td>
              </tr>
              {expandedTeam === teamId && (
                <tr className="expanded-row">
                  <td colSpan="3">
                    <ul className="member-list">
                      {groupedTeams[teamId].members.map((member, index) => (
                        <li key={index} className="member-item">
                          {member}
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    )
  }

  const renderGeneralView = () => {
    if (eventLoading) {
      return null
    }

    const componentDecorator = (href, text, key) => (
      <a className="mx-1" href={href} key={key} target="_blank" rel="noopener noreferrer">
        {translation.details_link}
      </a>
    )

    return (
      <div className="col-12 d-flex mt-0 position-relative">
        <div className="w-100">
          <p className="fw-bold fs-5">{event.event_name}</p>
          <img className="max-w-20 mx-auto d-block" src={event.event_logo || "/placeholder.svg"} alt="event_banner" />
          {event?.is_owner && (
            <IoCameraOutline role="button" className="fs-2 camera-outline" onClick={() => setOpenEventBanner(true)} />
          )}
          <p className="m-0 mb-2 text-start mt-3">
            <span className="event-card-label fw-bold">{translation.details}:</span>{" "}
            <Linkify componentDecorator={componentDecorator}>{event.details}</Linkify>
          </p>
          <p className="m-0 mb-2 text-start">
            <span className="event-card-label fw-bold">{translation.start_date}:</span> {formatDate(event.start_date)}
          </p>
          <p className="m-0 mb-2 text-start">
            <span className="event-card-label fw-bold">{translation.event_duration}:</span> {event.event_duration_hours}{" "}
            {translation.hours}
          </p>
          <p className="m-0 mb-2 text-start">
            <span className="event-card-label fw-bold">{translation.participation_tax}:</span> {event.participation_tax}{" "}
            RON
          </p>
          <p className="m-0 mb-2 text-start">
            <span className="event-card-label fw-bold">{translation.location}:</span>{" "}
            {locations?.length > 0 &&
              locations[0]?.cities?.find((item) => item.id === event.event_location_city).city_name}
            , {fishingspots?.find((item) => item.id === event.event_location).spot_name}
          </p>
          <p className="m-0 mb-2 text-start">
            <span className="event-card-label fw-bold">{translation.location_details}:</span>{" "}
            {event.event_location_details}
          </p>
          <div className="row w-100">
            {event.principal_sponsors.concat(event.other_sponsors).map((item, index) => (
              <SponsorsCard sponsor={item} index={index} key={index} />
            ))}
          </div>
        </div>
      </div>
    )
  }

  const renderFishScoring = () => {
    if (!event || !fish_types || event.event_fish_scoring.length < 1) {
      return null
    }

    return (
      <>
        {event.has_bonus_points && (
          <>
            <button className="galben text-white mb-3" onClick={handleBonusPointsButtonClick}>
              Puncte Bonus
              <IoCaretDownOutline
                className={`expand-icon ${isBonusExpanded ? "rotate-180" : ""}`}
                role="button"
                size={20}
                style={{ marginLeft: "8px" }}
              />
            </button>

            {isBonusExpanded && renderBonusPoints()}
          </>
        )}

        <FishScoringTable fishScoring={event.event_fish_scoring} fish_types={fish_types} />
      </>
    )
  }

  const renderPrizes = () => {
    if (!event && !fish_types) {
      return null
    }

    return (
      <>
        <FishPrizesTable fishPrizes={event.event_prizes} />
      </>
    )
  }

  const renderEnrollments = () => {
    if (!enrollments) {
      return null
    }

    return (
      <>
        {event?.is_team_event && (
          <button className="galben text-white mb-3" onClick={handleTeamButtonClick}>
            {translation.team_name}
            <IoCaretDownOutline
              className={`expand-icon ${isTeamsExpanded ? "rotate-180" : ""}`}
              role="button"
              size={20}
              style={{ marginLeft: "8px" }}
            />
          </button>
        )}
        {isTeamsExpanded && renderTeams()}
        <EnrollmentsTable enrollments={enrollments} isOwner={event?.is_owner} refreshEvent={refreshEvent} />
      </>
    )
  }

  const renderScoring = () => {
    if (event.is_ongoing) {
      return <RankingMovement />
    } else if (event.is_ended) {
      return (
        <>
          <ScoringTable scoring={scoring} event={event} />
        </>
      )
    } else {
      return <Empty label={translation.no_ranking} />
    }
  }

  const renderStats = () => {
    if (event.is_ended) {
      return <EventStats eventId={event.id} token={user.token} translation={translation} />
    } else {
      return <Empty label={translation.no_stats} />
    }
  }

  const renderContestatii = () => {
    return <ReportsTable data={contestatii} refreshReports={refreshReports} />
  }

  const renderTabContent = () => {
    switch (selectedTab) {
      case 0:
        return renderGeneralView()
      case 1:
        return renderFishScoring()
      case 2:
        return renderPrizes()
      case 3:
        return renderEnrollments()
      case 4:
        return renderScoring()
      case 5:
        return renderStats()
      case 6:
        return renderContestatii()
      default:
        return null
    }
  }

  return (
    <>
      <div className={`page-container container-fluid bg-white`}>
        {event && (
          <Helmet>
            <meta property="og:image" content={event.event_logo} />
            <title>{event.event_name}</title>
          </Helmet>
        )}
        <div className="row gx-3 g-3">
          <Tabs
            tabOptions={[
              translation.general,
              translation.scoring,
              translation.prizes,
              translation.enrollments,
              translation.ranking,
              translation.stats,
              translation.contestatii,
            ]}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            key={`tab_reference`}
          />
          <EventActions
            selectedTab={selectedTab}
            eventId={event?.id}
            event={event}
            is_active={event?.is_active}
            is_owner={event?.is_owner}
            is_ongoing={event?.is_ongoing}
            enrollment_status={event?.enrollment_status}
            is_validator={event?.validator === user?.account?.id}
            refreshEvent={refreshEvent}
            handleGenerateLineup={handleGenerateLineup}
            isGeneratingLineup={isGeneratingLineup}
            handleGenerateMatches={handleGenerateMatches}
            isGeneratingMatches={isGeneratingMatches}
            lineupExists={lineupMatchExists.lineup}
            matchExists={lineupMatchExists.match}
            handleDownloadLineup={handleDownloadLineup}
          />
          {renderTabContent()}
          <Modal isOpen={open} onClose={() => setOpen(false)} title={`Catch map for ${event?.event_name}`}>
            <MyEventCatchMap eventId={event?.id} />
          </Modal>
          {openEventBanner && <EditEventBanner eventId={event?.id} onUploadDone={() => setOpenEventBanner(false)} />}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  event: state.eventState.event,
  eventLoading: state.eventState.eventLoading,
  menuOpen: state.appState.menuOpen,
  translation: state.appState.translation,
  sponsors: state.utilsState.sponsors,
  fish_types: state.utilsState.fish_types,
  fishingspots: state.utilsState.fishingspots,
  locations: state.utilsState.locations,
  user: state.auth.user,
  scoring: state.eventsState.scoring,
  contestatii: state.eventsState.contestatii,
})

const mapDispatchToProps = (dispatch) => {
  return {
    loadEvent: (eventId) => {
      dispatch(loadEvent(eventId))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Event))

