import React from "react"
import { IoRibbon } from "react-icons/io5"
import { connect } from "react-redux"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  useMediaQuery,
  Box,
  Card,
  CardContent,
} from "@mui/material"
import { styled, useTheme } from "@mui/material/styles"

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(3),
  maxWidth: "100%",
  overflowX: "auto",
}))

const StyledTable = styled(Table)(({ theme }) => ({
  minWidth: 650,
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
  },
}))

const MobileCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const RibbonIcon = styled(IoRibbon)(({ className }) => ({
  fontSize: "1.5rem",
  ...(className === "golden" && { color: "#FFD700" }),
  ...(className === "silver" && { color: "#C0C0C0" }),
  ...(className === "bronze" && { color: "#CD7F32" }),
}))

const classNamesRibbon = ["golden", "silver", "bronze"]

const FishPrizesTable = ({ fishPrizes, translation }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const renderRow = (item, key) => {
    if (isMobile) {
      return (
        <MobileCard key={key}>
          <CardContent>
            <Box display="flex" alignItems="center" mb={1}>
              <Typography variant="h6" component="span" mr={1}>
                {key + 1}.
              </Typography>
              {key < 3 && <RibbonIcon className={classNamesRibbon[key]} />}
            </Box>
            <Typography variant="body1" gutterBottom>
              {item.prize_name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {translation.prize_value}: {item.prize_value}
            </Typography>
          </CardContent>
        </MobileCard>
      )
    }

    return (
      <TableRow key={key} hover>
        <TableCell>{key + 1}</TableCell>
        <TableCell>{key < 3 && <RibbonIcon className={classNamesRibbon[key]} />}</TableCell>
        <TableCell>{item.prize_name}</TableCell>
        <TableCell>{item.prize_value}</TableCell>
      </TableRow>
    )
  }

  return (
    <Paper elevation={3} sx={{ padding: 2, marginTop: 3 }}>
      {isMobile ? (
        <Box>{fishPrizes.map((item, index) => renderRow(item, index))}</Box>
      ) : (
        <StyledTableContainer>
          <StyledTable aria-label="fish prizes table">
            <TableHead>
              <TableRow>
                <StyledTableCell>#</StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell>{translation.prize_name}</StyledTableCell>
                <StyledTableCell>{translation.prize_value}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>{fishPrizes.map((item, index) => renderRow(item, index))}</TableBody>
          </StyledTable>
        </StyledTableContainer>
      )}
    </Paper>
  )
}

const mapStateToProps = (state) => ({
  translation: state.appState.translation,
})

export default connect(mapStateToProps, null)(FishPrizesTable)

