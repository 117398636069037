import React from "react"
import { connect } from "react-redux"

const DropdownScoringTypes = ({ name, label, options, selectedOption, onSelect, translation }) => {
  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <select className="form-control" id={name} name={name} value={selectedOption} onChange={onSelect}>
        <option value="">Select a scoring type</option>
        {options.map((type) => (
          <option key={type.scoring_type} value={type.scoring_type}>
            {translation[type.scoring_type] || type.label}
          </option>
        ))}
      </select>
    </div>
  )
}

const mapStateToProps = (state) => ({
  translation: state.appState.translation,
})

export default connect(mapStateToProps)(DropdownScoringTypes)

