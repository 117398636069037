import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { formatDate } from '../helpers/utils';
import { IoCalendarOutline, IoFishOutline , IoTimeOutline, IoLocationOutline, IoStar  } from 'react-icons/io5';

const getCardClassName = (is_ongoing, is_ended, is_active, enroll) => {
  if(is_ended) return 'ended';
  if(is_ongoing) return 'ongoing';
  if(enroll !== 'not enrolled') return 'enrolled';
  return 'new';
}

const EventCard = ({ event, translation, enroll, onClick, locations, fishingspots }) => {
  const navigate = useNavigate();
  const { is_ongoing, is_ended, is_active, enrollment_status } = event;

  const handleSelectedEvent = useCallback(() => {
    if(onClick) {
      onClick();
    } else {
      navigate(`/event/${event.id}`);
    }
  }, [event, navigate]);

  return (<div className={`col-lg-3 col-md-4 col-sm-6 col-xs-12 d-flex`} key={`${event.id} `}>
    <div className='card w-100'>
      <img src={event['event_logo']} alt="event_logo"/>
      <div className='p-1 color-blue rounded-bottom' style={{position: 'absolute', top:10, right: 10, textTransform: 'capitalize', border: '1px solid #ccc', background: 'rgba(255, 255, 255, 0.5)' }}>
        {getCardClassName(is_ongoing, is_ended, is_active, enrollment_status)}
      </div>
      <p className='fw-bold card-title pt-2'>{event['event_name']}</p>
      <div className='m-0 px-2 pt-1 d-flex mt-auto'><IoCalendarOutline className='fs-4 me-2 color-green' /> <span>{formatDate(event['start_date'])}</span></div>
      <div className='m-0 px-2 pt-1 d-flex'><IoTimeOutline className='fs-4 me-2 color-red'/> <span>{event.event_duration_hours} {translation.hours}</span></div>
      <div className='m-0 px-2 pt-1 d-flex'><IoFishOutline  className='fs-4 me-2 color-blue'/> <span>{event.species_count}</span></div>
      <div className='m-0 px-2 pt-1 d-flex pb-3'><IoLocationOutline  className='fs-4 me-2 color-green'/> <span>{locations[0]?.cities?.find((item) => item.id === event.event_location_city).city_name}, {fishingspots?.find((item) => item.id === event.event_location).spot_name}</span></div>

      <button className='btn blue text-white mb-0 rounded-bottom' onClick={() => handleSelectedEvent(event)}>{translation.event_cta}</button>
    </div>  
  </div>);
}

const mapStateToProps = state => ({
    translation: state.appState.translation,
    locations: state.utilsState.locations,
    fishingspots: state.utilsState.fishingspots
});

export default connect(mapStateToProps, null)(React.memo(EventCard));
