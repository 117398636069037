import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CheckCircle } from '@mui/icons-material';
import { toast } from 'react-toastify';

const ColorButton = styled(Button)(({ theme, color }) => ({
  backgroundColor: `var(--${color})`,
  color: 'var(--white)',
  '&:hover': {
    backgroundColor: `var(--${color})`,
    opacity: 0.9,
  },
}));

const MatchCard = ({ match, eventId, onUpdate, translation, updateLoading, refreshCard, currentLeg }) => {
  const [catches, setCatches] = useState(match.my_catches);

  useEffect(() => {
    setCatches(match.my_catches);
  }, [match]);

  const handleValidateOpponent = async () => {
    const result = await onUpdate(eventId, match.match_id, { validate_opponent: true });
    if (result.success) {
      toast.success(result.message || translation.validate_success || 'Opponent catches validated successfully');
      if (typeof refreshCard === 'function') {
        refreshCard();
      }
      return;
    }

    if (result.error === "Match locked (both validated).") {
      toast.info(translation.match_locked || 'Match is already validated by both players');
    } else {
      toast.error(result.error || translation.validate_error || 'Failed to validate opponent catches');
    }
  };

  const handleUpdateCatches = async () => {
    if (catches === null) return;

    const result = await onUpdate(eventId, match.match_id, { catches });
    if (result.success) {
      toast.success(result.message || translation.update_success || 'Catches updated successfully');
      if (typeof refreshCard === 'function') {
        refreshCard();
      }
      return;
    }

    if (result.error === "Match locked (both validated).") {
      toast.info(translation.match_locked || 'Match is already validated by both players');
    } else {
      toast.error(result.error || translation.update_error || 'Failed to update catches');
    }
  };

  return (
    <Card
      sx={{
        backgroundColor: 'var(--pagebg)',
        boxShadow: match.leg_number === currentLeg
          ? '0px 0px 15px var(--maro1)'
          : '0px 4px 10px var(--shadow1)',
        borderRadius: '4px',
        overflow: 'hidden',
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                backgroundColor: match.leg_number === currentLeg ? 'var(--maro1)' : 'transparent',
                padding: '8px',
                borderRadius: '4px 4px 0 0',
                marginBottom: '8px',
              }}
            >
              <Typography variant="h6" sx={{
                color: match.leg_number === currentLeg ? 'var(--white)' : 'var(--maro1)',
                fontWeight: 'bold',
              }}>
                {translation.leg || 'Leg'} {match.leg_number}
                {/*{match.leg_number === currentLeg && (*/}
                {/*  <span style={{ marginLeft: '10px' }}>*/}
                {/*    [{translation.current_leg || 'Current Leg'}]*/}
                {/*  </span>*/}
                {/*)}*/}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="subtitle1" fontWeight="bold" sx={{ color: 'var(--green1)' }}>
              {match.my_name}
            </Typography>
            <Typography variant="body2" sx={{ color: 'var(--shadow1)' }}>
              {translation.seat || 'Box'} {match.my_seat_no}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="subtitle1" fontWeight="bold" sx={{ color: 'var(--red1)' }}>
              {match.opponent_name}
            </Typography>
            <Typography variant="body2" sx={{ color: 'var(--shadow1)' }}>
              {translation.seat || 'Box'} {match.opponent_seat_no}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" flexDirection="column" gap={2}>
              <TextField
                type="number"
                value={catches ?? ''}
                onChange={(e) => setCatches(e.target.value ? Number(e.target.value) : null)}
                label={translation.catches || 'My Catches'}
                disabled={match.my_valid}
                size="small"
                fullWidth
                sx={{ '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: 'var(--blue1)' } } }}
              />
              <ColorButton
                color="green1"
                onClick={handleUpdateCatches}
                disabled={updateLoading || catches === null || match.my_valid}
              >
                {updateLoading ? (translation.updating || 'Updating...') : (translation.update || 'Add My Catches')}
              </ColorButton>

              {match.validate_opponent_catches && (
                <ColorButton
                  color="blue1"
                  onClick={handleValidateOpponent}
                  disabled={updateLoading}
                >
                  {translation.validate_opponent || 'Validate Opponent'}
                </ColorButton>
              )}
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Typography sx={{ color: 'var(--blue1)', display: 'flex', alignItems: 'center', gap: 1 }}>
              {translation.my_catches || 'My catches'}: <span className="color-green">{match.my_catches ?? '-'}</span>
              {match.my_valid && <CheckCircle sx={{ color: 'var(--green1)' }} />}
            </Typography>
            <Typography sx={{ color: 'var(--blue1)' }}>
              {translation.my_points || 'My points'}: <span className="color-green">{match.my_points ?? '-'}</span>
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography sx={{ color: 'var(--blue1)', display: 'flex', alignItems: 'center', gap: 1 }}>
              {translation.opponent_catches || 'Opp. catches'}: <span className="color-red">{match.opponent_catches ?? '-'}</span>
              {match.opponent_valid && <CheckCircle sx={{ color: 'var(--green1)' }} />}
            </Typography>
            <Typography sx={{ color: 'var(--blue1)' }}>
              {translation.opponent_points || 'Opp. points'}: <span className="color-red">{match.opponent_points ?? '-'}</span>
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MatchCard;

