import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Container, Typography, Grid } from '@mui/material';
import { fetchGameCards, updateMatch } from '../actions/taActions';
import { loadEvent } from '../actions/eventsState';
import MatchCard from '../components/TaMatchCard';
import Loader from '../components/Loader';

const TaEventOngoing = ({
  gameCard,
  loading,
  error,
  updateLoading,
  event,
  translation,
  fetchGameCards,
  updateMatch,
  loadEvent
}) => {
  const { eventId } = useParams();
  const navigate = useNavigate();

  const fetchData = useCallback(() => {
    if (eventId) {
      fetchGameCards(eventId);
    }
  }, [eventId, fetchGameCards]);

  useEffect(() => {
    if (!eventId) {
      navigate('/');
    } else {
      loadEvent(eventId);
    }
  }, [eventId, loadEvent, navigate]);

  useEffect(() => {
    if (
      event &&
      (
        !event?.is_ongoing ||
        (
          event?.enrollement_status === 'not enrolled' ||
          event?.enrollement_status === 'rejected'
        )
      )
    ) {
      navigate('/');
    }
  }, [event, navigate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  console.log('TaEventOngoing render', { gameCard, loading, error, updateLoading, event });

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Container>
        <Typography color="error">
          {translation?.error || 'Error'}: {error}
        </Typography>
      </Container>
    );
  }

  if (!gameCard || !event) {
    return <Container><Typography>No game card or event data available.</Typography></Container>;
  }

  return (
    <Container>
      <Box py={4}>
        <Typography variant="h4" gutterBottom>
          {event?.event_name || translation?.ongoing_tournament || 'Ongoing Tournament'}
        </Typography>
        <Typography variant="h6" gutterBottom>
          {gameCard.user_name} - {translation.current_leg || 'Current Leg'}: {gameCard.current_leg}
        </Typography>

        <Grid container spacing={3}>
          {gameCard.matches && gameCard.matches.map((match) => (
            <Grid item xs={12} md={6} lg={4} key={match.match_id}>
              <MatchCard
                match={match}
                eventId={eventId}
                onUpdate={updateMatch}
                translation={translation || {}}
                updateLoading={updateLoading}
                refreshCard={fetchData}
                event={event}
                currentLeg={gameCard.current_leg}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => {
  console.log('mapStateToProps', state);
  return {
    event: state.eventState.event,
    gameCard: state.taStateReducer?.gameCard,
    loading: state.taStateReducer?.loading,
    error: state.taStateReducer?.error,
    updateLoading: state.taStateReducer?.updateLoading,
    translation: state.appState?.translation
  };
};

const mapDispatchToProps = {
  fetchGameCards,
  updateMatch,
  loadEvent
};

export default connect(mapStateToProps, mapDispatchToProps)(TaEventOngoing);

