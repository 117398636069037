import React, { useState } from "react"
import { connect } from "react-redux"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  useMediaQuery,
  Box,
  Card,
  CardContent,
} from "@mui/material"
import { styled, useTheme } from "@mui/material/styles"

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(3),
  maxWidth: "100%",
  overflowX: "auto",
}))

const StyledTable = styled(Table)(({ theme }) => ({
  minWidth: 650,
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
  },
}))

const YellowTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.main,
  textAlign: "right",
  marginBottom: theme.spacing(1),
}))

const MobileCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const FishScoringTable = ({ fishScoring, fish_types, translation }) => {
  const [fishScoringXCatch] = useState(fishScoring[0]?.top_x_catches)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const renderRow = (item, key) => {
    const fishType = fish_types.find((fish) => fish.id === item.fish)

    if (isMobile) {
      return (
        <MobileCard key={key}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {fishType.name} ({fishType.scientific_name})
            </Typography>
            <Typography variant="body2">
              {translation.min_length}: {item.accountable_min_length} cm
            </Typography>
            <Typography variant="body2">
              {translation.under_length_points}: {item.under_min_length_points}
            </Typography>
            {item.scoring_type !== "Top_X_Catches" && (
              <Typography variant="body2">
                {translation.catch_slots}: {item.accountable_catch_slots}
              </Typography>
            )}
          </CardContent>
        </MobileCard>
      )
    }

    return (
      <TableRow key={key} hover>
        <TableCell>{key + 1}</TableCell>
        <TableCell>
          {fishType.name} ({fishType.scientific_name})
        </TableCell>
        {item.scoring_type !== "Top_X_Catches" && <TableCell>{item.accountable_catch_slots}</TableCell>}
        <TableCell>{item.accountable_min_length} cm</TableCell>
        <TableCell>{item.under_min_length_points}</TableCell>
      </TableRow>
    )
  }

  return (
    <Paper elevation={3} sx={{ padding: 2, marginTop: 3 }}>
      <YellowTypography variant="body2">
        *
        {fishScoringXCatch
          ? `Puncteaza primii ${fishScoringXCatch} pesti din speciile afisate.`
          : "Puncteaza sloturile conform speciilor afisate."}
      </YellowTypography>
      {isMobile ? (
        <Box>{fishScoring.map((item, index) => renderRow(item, index))}</Box>
      ) : (
        <StyledTableContainer>
          <StyledTable aria-label="fish scoring table">
            <TableHead>
              <TableRow>
                <StyledTableCell>#</StyledTableCell>
                <StyledTableCell>{translation.fish}</StyledTableCell>
                {fishScoring[0].scoring_type !== "Top_X_Catches" && (
                  <StyledTableCell>{translation.catch_slots}</StyledTableCell>
                )}
                <StyledTableCell>{translation.min_length}</StyledTableCell>
                <StyledTableCell>{translation.under_length_points}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>{fishScoring.map((item, index) => renderRow(item, index))}</TableBody>
          </StyledTable>
        </StyledTableContainer>
      )}
    </Paper>
  )
}

const mapStateToProps = (state) => ({
  translation: state.appState.translation,
})

export default connect(mapStateToProps, null)(FishScoringTable)

