import React, { useEffect, useState } from "react"
import Input from "../components/Input"
import DropdownFishTypes from "../components/DropdownFishTypes"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import { IoTrashBin, IoAddCircleOutline } from "react-icons/io5"
import { postFishScoring } from "../actions/eventsState"
import { eventFishScoringForm, eventFishScoringTopCatches } from "../constants/forms"
import { toast } from "react-toastify"
import { Container, Paper, Typography, Stepper, Step, StepLabel, Grid, Button, CircularProgress } from "@mui/material"
import { styled } from "@mui/material/styles"

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
}))

const StyledForm = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
}))

const ScoringContainer = styled("div")(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

const initialStateForm = {
  event: null,
  fish: null,
  accountable_catch_slots: 1,
  accountable_min_length: 0,
  under_min_length_points: 0,
}

const CreateEventStepTwo = ({ createdEvent, translation, postFishScoring, fish_types, step, createLoading }) => {
  const navigate = useNavigate()
  const [dirtyForm, setDirtyForm] = useState(false)
  const [stepTwoData, setstepTwoData] = useState([{ ...initialStateForm, event: createdEvent?.id }])
  const [formData, setFormData] = useState(eventFishScoringForm)
  const [topX, setTopX] = useState(null)

  useEffect(() => {
    if (!createdEvent) {
      navigate("/dashboard")
    } else {
      setFormData(createdEvent.scoring_type === "Top_X_Catches" ? eventFishScoringTopCatches : eventFishScoringForm)
    }
  }, [createdEvent, navigate])

  useEffect(() => {
    if (step === 3) {
      navigate("/create-prizes")
    }
  }, [step, navigate])

  const handleChange = (e, indexScore) => {
    const { name, value } = e.target
    const stateData = [...stepTwoData]

    stateData[indexScore][name] = value
    setstepTwoData([...stateData])
    setDirtyForm(true)
  }

  const addScoring = (e) => {
    e.preventDefault()
    const stateData = [...stepTwoData]
    stateData.push({ ...initialStateForm, event: createdEvent.id })

    setstepTwoData([...stateData])
    setDirtyForm(true)
  }

  const deleteScoring = (e, indexScore) => {
    e.preventDefault()
    const stateData = [...stepTwoData]

    if (stateData.length === 1) {
      return
    }

    stateData.splice(indexScore, 1)

    setstepTwoData([...stateData])
    setDirtyForm(true)
  }

  const nextStep = (e) => {
    e.preventDefault()
    if (validateMandatory(stepTwoData) && validateTopX()) {
      const arrayObjectKeys = Object.keys(initialStateForm)
      const data = [...stepTwoData]

      for (let i = 0; i < data.length; i++) {
        for (let k = 0; k < arrayObjectKeys.length; k++) {
          if (!(typeof data[i][arrayObjectKeys[k]] === "number")) {
            data[i][arrayObjectKeys[k]] = Number.parseInt(data[i][arrayObjectKeys[k]])
          }
        }
      }

      for (let i = 0; i < data.length; i++) {
        data[i].scoring_type = createdEvent.scoring_type
        data[i].top_x_catches = topX
      }

      postFishScoring(data)
    } else {
      toast.error("Please complete all mandatory fields")
    }
  }

  const validateMandatory = (data) => {
    let validData = true
    const arrayObjectKeys = Object.keys(initialStateForm)

    for (let i = 0; i < data.length; i++) {
      for (let k = 0; k < arrayObjectKeys.length; k++) {
        if (!data[i][arrayObjectKeys[k]]) {
          validData = false
        }
      }
    }

    return validData
  }

  const validateTopX = () => (createdEvent.scoring_type === "Top_X_Catches" ? topX : true)

  const renderForm = (indexScore) =>
    Object.keys(formData).map((key, index) => {
      switch (formData[key].type) {
        case "number":
          return (
            <Grid item xs={12} sm={6} key={key + index}>
              <Input
                type="number"
                className="form-control"
                name={formData[key].label}
                label={translation[formData[key].label]}
                placeholder={translation[formData[key].placeholder]}
                value={stepTwoData[indexScore][key] || ""}
                onChange={(e) => handleChange(e, indexScore)}
                formAttr={key}
              />
            </Grid>
          )
        case "dropdown_fishtypes":
          return (
            <Grid item xs={12} sm={6} key={key + index}>
              <DropdownFishTypes
                type="text"
                className="form-control text-start"
                name={formData[key].label}
                label={translation[formData[key].label]}
                options={fish_types}
                selectedOptions={stepTwoData[indexScore][formData[key].label]}
                onSelect={(e) => handleChange(e, indexScore)}
                formAttr={key}
              />
            </Grid>
          )
        default:
          return null
      }
    })

  return (
    <Container maxWidth="md">
      <StyledPaper elevation={3}>
        <Typography variant="h4" gutterBottom>
          {translation.create_event}
        </Typography>
        <Stepper activeStep={1} alternativeLabel sx={{ marginBottom: 3 }}>
          <Step>
            <StepLabel>{translation.general}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{translation.scoring}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{translation.prizes}</StepLabel>
          </Step>
        </Stepper>
        <StyledForm onSubmit={nextStep}>
          <Typography variant="subtitle1" align="right" color="primary">
            {`Creating single event`}
          </Typography>
          <Typography variant="h6" gutterBottom>
            {translation.scoring_type_reminder}: {translation[createdEvent.scoring_type]}
          </Typography>
          {createdEvent.scoring_type === "Top_X_Catches" && (
            <Grid item xs={12} sm={6}>
              <Input
                type="number"
                className="form-control"
                name={"topx"}
                label={translation.topx}
                placeholder={translation.topx}
                value={topX}
                onChange={(e) => setTopX(e.target.value)}
              />
            </Grid>
          )}
          {stepTwoData.map((_item, indexScore) => (
            <ScoringContainer key={indexScore}>
              <Grid container spacing={2}>
                {renderForm(indexScore)}
              </Grid>
              <Button
                variant="contained"
                color="error"
                startIcon={<IoTrashBin />}
                onClick={(e) => deleteScoring(e, indexScore)}
                sx={{ mt: 2, alignSelf: "flex-end" }}
              >
                {translation.deleteScoring}
              </Button>
            </ScoringContainer>
          ))}
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button variant="contained" color="success" startIcon={<IoAddCircleOutline />} onClick={addScoring}>
                {translation.addScoring}
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained" color="primary" disabled={createLoading}>
                {createLoading ? <CircularProgress size={24} /> : translation.next_step}
              </Button>
            </Grid>
          </Grid>
        </StyledForm>
      </StyledPaper>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  createdEvent: state.eventsState.createdEvent,
  menuOpen: state.appState.menuOpen,
  translation: state.appState.translation,
  fish_types: state.utilsState.fish_types,
  step: state.eventsState.step,
  createLoading: state.eventsState.createLoading,
})

const mapDispatchToProps = (dispatch) => {
  return {
    postFishScoring: (stepTwoData) => dispatch(postFishScoring(stepTwoData)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CreateEventStepTwo))

