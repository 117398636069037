import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import { IoTrashBin } from "react-icons/io5"
import { postPrizes } from "../actions/eventsState"
import Input from "../components/Input"
import { eventPrizes } from "../constants/forms"
import { Container, Paper, Typography, Stepper, Step, StepLabel, Grid, Button, CircularProgress } from "@mui/material"
import { styled } from "@mui/material/styles"

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
}))

const StyledForm = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
}))

const PrizeContainer = styled("div")(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

const initialStateForm = {
  event: null,
  prize_name: "",
  prize_value: "",
}

const CreateEventStepThree = ({ createdEvent, translation, postPrizes, createLoading, step }) => {
  const navigate = useNavigate()
  const [dirtyForm, setDirtyForm] = useState(false)
  const [stepThreeData, setstepThreeData] = useState([{ ...initialStateForm, event: createdEvent?.id }])

  useEffect(() => {
    if (!createdEvent) {
      navigate("/dashboard")
    }
  })

  useEffect(() => {
    if (step === 4) {
      navigate("/success")
    }
  }, [step, navigate])

  const handleChange = (e, indexScore) => {
    const { name, value } = e.target
    const stateData = [...stepThreeData]
    stateData[indexScore][name] = value
    setstepThreeData([...stateData])
    setDirtyForm(true)
  }

  const handleNumberChange = (e, indexScore) => {
    const { name, value } = e.target
    const stateData = [...stepThreeData]
    stateData[indexScore][name] = Number.parseInt(value, 10)
    setstepThreeData([...stateData])
    setDirtyForm(true)
  }

  const addPrizeSection = (e) => {
    e.preventDefault()
    const stateData = [...stepThreeData]
    stateData.push({ ...initialStateForm, event: createdEvent.id })

    setstepThreeData([...stateData])
    setDirtyForm(true)
  }

  const deletePrizeSection = (e, indexScore) => {
    e.preventDefault()
    const stateData = [...stepThreeData]
    if (stateData.length === 1) {
      return
    }

    stateData.splice(indexScore, 1)

    setstepThreeData([...stateData])
    setDirtyForm(true)
  }

  const nextStep = (e) => {
    e.preventDefault()
    validate(stepThreeData)
    postPrizes(stepThreeData)
  }

  const validate = (data) => {
    let validData = true
    const arrayObjectKeys = Object.keys(initialStateForm)

    for (let i = 0; i < data.length; i++) {
      for (let k = 0; k < arrayObjectKeys.length; k++) {
        if (!data[i][arrayObjectKeys[k]]) {
          validData = false
        }
      }
    }

    return validData
  }

  const previousStep = (e) => {
    e.preventDefault()
  }

  const renderForm = (indexScore) =>
    Object.keys(eventPrizes).map((key, index) => {
      switch (eventPrizes[key].type) {
        case "number":
          return (
            <Grid item xs={12} sm={6} key={key + index}>
              <Input
                type="number"
                className="form-control"
                name={eventPrizes[key].label}
                label={translation[eventPrizes[key].label]}
                placeholder={translation[eventPrizes[key].placeholder]}
                value={stepThreeData[indexScore][key] || ""}
                onChange={(e) => handleNumberChange(e, indexScore)}
                formAttr={key}
              />
            </Grid>
          )
        case "string":
          return (
            <Grid item xs={12} sm={6} key={key + index}>
              <Input
                type="string"
                className="form-control"
                name={eventPrizes[key].label}
                label={translation[eventPrizes[key].label]}
                placeholder={translation[eventPrizes[key].placeholder]}
                value={stepThreeData[indexScore][key] || ""}
                onChange={(e) => handleChange(e, indexScore)}
                formAttr={key}
              />
            </Grid>
          )
        default:
          return null
      }
    })

  return (
    <Container maxWidth="md">
      <StyledPaper elevation={3}>
        <Typography variant="h4" gutterBottom>
          {translation.create_event}
        </Typography>
        <Stepper activeStep={2} alternativeLabel sx={{ marginBottom: 3 }}>
          <Step>
            <StepLabel>{translation.general}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{translation.scoring}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{translation.prizes}</StepLabel>
          </Step>
        </Stepper>
        <StyledForm onSubmit={nextStep}>
          <Typography variant="subtitle1" align="right" color="primary">
            {`Creating single event`}
          </Typography>
          {stepThreeData.map((_item, indexScore) => (
            <PrizeContainer key={indexScore}>
              <Grid container spacing={2}>
                {renderForm(indexScore)}
              </Grid>
              <Button
                variant="contained"
                color="error"
                startIcon={<IoTrashBin />}
                onClick={(e) => deletePrizeSection(e, indexScore)}
                sx={{ mt: 2, alignSelf: "flex-end" }}
              >
                {translation.deletePrizeSection}
              </Button>
            </PrizeContainer>
          ))}
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button variant="contained" color="success" onClick={addPrizeSection}>
                {translation.addPrizeSection}
              </Button>
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained" color="primary" disabled={createLoading}>
                {createLoading ? <CircularProgress size={24} /> : translation.next_step}
              </Button>
            </Grid>
          </Grid>
        </StyledForm>
      </StyledPaper>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  createdEvent: state.eventsState.createdEvent,
  step: state.eventsState.step,
  createLoading: state.eventsState.createLoading,
  menuOpen: state.appState.menuOpen,
  translation: state.appState.translation,
  fish_types: state.utilsState.fish_types,
})

const mapDispatchToProps = (dispatch) => {
  return {
    postPrizes: (stepThreeData) => dispatch(postPrizes(stepThreeData)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(CreateEventStepThree))

